<template>
  <main class="projects-group">
    <div class="btn-primary-l"><p class="-ntr">Manager</p></div>
    <ul>
      <li
        v-for="project in projects"
        :style="[
          project.imagePath
            ? `background-image: url('${project.imagePath}')`
            : `background-image: var(--project-thumbnail);`,
        ]"
        :key="project"
        :ref="`p${project.id}`"
        @click="selectProject(project, `p${project.id}`)"
      >
        <div class="project-banner">
          <p class="-xstr">{{ project.project_name }}</p>
        </div>
      </li>
    </ul>
  </main>
  <main class="projects-group">
    <div class="btn-primary-l"><p class="-ntr">Member</p></div>
    <ul class="empty-ul">
      <li class="empty-li"></li>
      <li class="empty-li"></li>
      <li class="empty-li"></li>
      <li class="empty-li"></li>
    </ul>
  </main>
  <main class="projects-group">
    <div class="btn-primary-l"><p class="-ntr">Guest</p></div>
    <ul class="empty-ul">
      <li class="empty-li"></li>
      <li class="empty-li"></li>
      <li class="empty-li"></li>
      <li class="empty-li"></li>
    </ul>
  </main>
</template>

<script>
export default {
  props: ["projects"],
  emits: ["selectProject"],
  data() {
    return {
      selectedRef: null,
      projectBackgroundImage: "backgroundImage: `url('${project.imagePath}')`",
    };
  },
  mounted() {
    //simula o efeito do ultimo projecto como escolhido quando entra na listagem - virá da api o ultimo em que o user esteve
    this.selectProject(this.projects[0], `p${this.projects[0].id}`);
  },
  methods: {
    selectProject(project, ref) {
      if (!this.selectedRef) {
        this.selectedRef = this.$refs[ref][0];
        this.selectedRef.classList.add("active");
      } else if (this.selectedRef !== ref[0]) {
        this.selectedRef.classList.remove("active");
        this.selectedRef = this.$refs[ref][0];
        this.selectedRef.classList.add("active");
      } else {
        return;
      }

      this.$emit("selectProject", project);
    },
  },
};
</script>

<style scoped>
.projects-group {
  grid-column: span 6;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: left;
}
.btn-primary-l {
  cursor: default;
}
.btn-primary-l:hover {
  transform: none;
}
ul {
  height: 100%;
  width: 100%;
  user-select: none;
  padding-inline: 0.5rem;
  display: grid;
  grid-auto-flow: column; /* Era preciso usar essa linha */
  grid-auto-columns: 128px; /* Era preciso deixar assim essa linha */
  grid-template-rows: 100%;
  gap: 32px;
  overflow-y: hidden; /* Deixei essa hidden pois acredito nao mudar nada */
  overflow-x: auto; /* Era preciso deixar auto essa linha linha */
}
li {
  cursor: pointer;
  filter: grayscale(100%);
  grid-column: auto;
  grid-row: span 1;
  align-self: center;
  height: 128px;
  width: 128px;
  border-radius: 8px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  transition: transform 100ms ease-in-out;
}
.empty-ul {
  overflow: hidden;
}
.empty-li {
  cursor: default;
  grid-column: auto;
  grid-row: span 1;
  align-self: center;
  height: 128px;
  width: 128px;
  border-radius: 8px;
  background-image: none;
  border: 2px dashed var(--gray4);
  background: transparent;
}
.project-banner {
  height: 50px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: rgba(0, 0, 0, 0.25);
  color: var(--white1);
  display: flex;
  align-items: center;
  justify-content: center;
}

/*=== EFFECTS ===*/
li:hover {
  transform: scale(1.1);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.empty-li:hover {
  transform: none;
  box-shadow: none;
}
.active {
  transform: scale(1.1);
  filter: none;
}
</style>